import { kea } from 'kea';
import _ from 'lodash';

/**
 * Logic to handle all the states
 */
const logic: any = kea({
	path: () => ['kea', 'main'],

	defaults: {
		selectedProduct: {},
	},

	actions: {
		setSelectedProduct: (selectedProduct: any) => ({ selectedProduct }),
	},

	listeners: ({ actions, values }) => ({}),

	reducers: ({ values }) => ({
		selectedProduct: [
			[],
			{
				setSelectedProduct: (state: any, { selectedProduct }: any) => selectedProduct,
			},
		],
	}),
});

export default logic;
