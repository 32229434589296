import React from 'react';
import './App.css';
import { Switch, Route, HashRouter } from 'react-router-dom';
import 'antd/dist/antd.css';

import Products from './Products';
import ProductDetail from './ProductDetials';
import Payment from './Payment';
import EmailVerify from './VerifyEmail';
import BackofficeLogin from './BackofficeLogin';
import BackofficeDashboard from './BackofficeDashboard';
import ReportSent from './SentReport';

import WealthLogin from '../src/WeathManagementComponents/MutualFunds/Login';
import WealthDashboard from '../src/WeathManagementComponents/MutualFunds/Dashboard';

import StocksLogin from '../src/WeathManagementComponents/Stocks/Login';
import StocksDashboard from '../src/WeathManagementComponents/Stocks/Dashboard';

const App = () => {
	return (
		<HashRouter>
			<Switch>
				<Route exact path='/' render={() => <Products />} />
				<Route exact path='/buy' render={() => <ProductDetail />} />
				<Route exact path='/payment' render={() => <Payment />} />
				<Route exact path='/email-verify' render={() => <EmailVerify />} />
				<Route exact path='/send-report' render={() => <ReportSent />} />
				<Route exact path='/backoffice/login' render={() => <BackofficeLogin />} />
				<Route exact path='/backoffice/dashboard' render={() => <BackofficeDashboard />} />
				<Route exact path='/backoffice/orders' render={() => <EmailVerify />} />

				<Route exact path='/wealth/mutual-funds/login' render={() => <WealthLogin />} />
				<Route exact path='/wealth/mutual-funds/dashboard' render={() => <WealthDashboard />} />

				<Route exact path='/wealth/stocks/login' render={() => <StocksLogin />} />
				<Route exact path='/wealth/stocks/dashboard' render={() => <StocksDashboard />} />
			</Switch>
		</HashRouter>
	);
};

export default App;
