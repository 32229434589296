import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import {
	Avatar,
	Button,
	Card,
	Col,
	Descriptions,
	Layout,
	Menu,
	Modal,
	Rate,
	Result,
	Row,
	Statistic,
	Typography,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Meta from 'antd/lib/card/Meta';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import firebaseDB from './firebase';
import MainLogic from './Logics/mainLogic';
import { useValues } from 'kea';

const { Paragraph } = Typography;
const { info } = Modal;

const ProductDetail = (props: any) => {
	const { selectedProduct } = useValues(MainLogic);
	const handlePayment = () => {
		firebaseDB.child('activeOrders/item1').set(selectedProduct, (err) => console.log(err));
		info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					status='success'
					title='Order successfully placed!'
					subTitle='Order number: 2017182818828182881 Order confirmation may take 10-15 min, please wait.'
				/>
			),
		});
	};
	return (
		<Layout>
			<Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
				<Row justify='space-around'>
					<Col span={22}>
						<Menu theme='dark' mode='horizontal' style={{ width: '50%' }}>
							<Menu.Item key='1' onClick={() => props.history.push('/')}>
								Products
							</Menu.Item>
							<Menu.Item key='2'>Orders</Menu.Item>
							<Menu.Item key='3'>Basket</Menu.Item>
						</Menu>
					</Col>
					<Col span={2}>
						<Avatar icon={<UserOutlined />} />
					</Col>
				</Row>
			</Header>
			<Content
				className='site-layout'
				style={{ padding: '0 50px', marginTop: 64, maxHeight: '90vh', overflow: 'scroll' }}
			>
				<div className='site-layout-background' style={{ padding: 24, minHeight: 380 }}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Button type='primary' style={{ float: 'right' }} onClick={handlePayment}>
								Place Order
							</Button>
						</Col>
						<Col span={8}>
							<Card title='Shipping Address' extra={<EditOutlined />}>
								<Typography.Title level={4}>Arqam Ali</Typography.Title>
								<Typography.Paragraph>
									#22115, 5Th Floor, City Center 2, Bukhara Street, Near Ramanda Hotel, Sharjah
								</Typography.Paragraph>
								<Typography.Paragraph>DUBAI</Typography.Paragraph>
								<Typography.Text>Phone: 555555577</Typography.Text>
							</Card>
						</Col>
						<Col span={8}>
							<Card title='Billing Address' extra={<EditOutlined />}>
								<Typography.Title level={4}>Arqam Ali</Typography.Title>
								<Typography.Paragraph>
									#22115, 5Th Floor, City Center 2, Bukhara Street, Near Ramanda Hotel, Sharjah
								</Typography.Paragraph>
								<Typography.Paragraph>DUBAI</Typography.Paragraph>
								<Typography.Text>Phone: 555555577</Typography.Text>
							</Card>
						</Col>
						<Col span={8}>
							<Card title='Payment Details' extra={<EditOutlined />}>
								<Row gutter={16}>
									<Col span={24}>
										<Statistic title='Name on Card' value='Mohammed Ali' />
									</Col>
									<Col span={18}>
										<Statistic title='Card Number' value='XXXX-XXXX-XXXX-X234' />
									</Col>
									<Col span={6}>
										<Statistic title='Expiry' value='12-25' />
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default withRouter(ProductDetail);
