import { Rate } from 'antd';
import _ from 'lodash';

export const fundsColums: any = [
	{
		title: 'Merket Name',
		dataIndex: 'MarketName',
		key: 'MarketName',
		width: 200,
	},
	{
		title: 'Stock Name',
		dataIndex: 'StockName',
		key: 'StockName',
		width: 200,
	},
	{
		title: 'Units',
		dataIndex: 'Units',
		key: 'Units',
		width: 100,
	},
	{
		title: 'NAV Change (AED)',
		dataIndex: 'NAVChange',
		key: 'NAVChange',
		width: 100,
	},
	{
		title: 'NAV Change (%)',
		dataIndex: 'NAVChangePercent',
		key: 'NAVChangePercent',
		width: 100,
	},
	{
		title: '30-Day Yield (%)',
		dataIndex: '30DayYield',
		key: '30DayYield',
		width: 100,
	},
	{
		title: '7-Day Yield (%)',
		dataIndex: '7DayYield',
		key: '7DayYield',
		width: 100,
	},
	{
		title: 'Minimum Investment',
		dataIndex: 'MinimumInvestment',
		key: 'MinimumInvestment',
		width: 100,
	},
	{
		title: 'Last Dividend',
		dataIndex: 'LastDividend',
		key: 'LastDividend',
		width: 100,
	},
];
