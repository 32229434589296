import React, { PureComponent, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import CustomLayout from './Layout';
import { Card, Tabs, Form, Col, Row, Select, DatePicker, Table, Spin, Modal, Result, Button } from 'antd';
import moment from 'moment';
import { fundsColums } from './Columns';

import firebaseDB from '../../firebase';
import { SyncOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const categories = ['Equity', 'Short Term', 'Long Term', 'Hybrid', 'Tax Saving'];
const customer = ['All', 'Customer 1', 'Customer 2'];

class MutualFundsDashboard extends PureComponent<any, any> {
	state = { fundData: [], spin: false };
	componentDidMount = () => {
		firebaseDB.child('wealthData/stocks').on('value', (snap) => {
			if (snap.val() !== null) this.setState({ fundData: snap.val() });
		});
	};

	handleSpinner = () => {
		this.setState({ spin: true });
		setTimeout(() => {
			this.setState({ spin: false });
		}, 3000);
	};

	showDataCollection = () => {
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					icon={<SyncOutlined spin />}
					status='success'
					title='Collecting stocks data from all sorces...'
					subTitle='Report will be downloaded in a while'
				/>
			),
		});

		setTimeout(() => {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href =
				'https://www.stats.govt.nz/assets/Uploads/Annual-enterprise-survey/Annual-enterprise-survey-2019-financial-year-provisional/Download-data/annual-enterprise-survey-2019-financial-year-provisional-csv.csv';
			a.click();
		}, 5000);
	};

	render() {
		console.log(this.state);
		return (
			<CustomLayout>
				<Spin spinning={this.state.spin}>
					<Card
						size='small'
						extra={
							<Button type='primary' size='small' onClick={this.showDataCollection}>
								Download Stocks Report
							</Button>
						}
					>
						<Table
							className='stocks-table'
							pagination={false}
							size='small'
							dataSource={_.get(this.state, 'fundData', [])}
							columns={fundsColums}
							scroll={{ x: '100%', y: '70vh' }}
						/>
					</Card>
				</Spin>
			</CustomLayout>
		);
	}
}

export default withRouter(MutualFundsDashboard);
