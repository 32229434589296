import React, { useEffect } from 'react';
import _ from 'lodash';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

const MutualFundsLogin = (props: any) => {
	const handleLogin = () => {
		props.history.push('/wealth/stocks/dashboard');
	};

	return (
		<Row style={{ height: '100vh', width: '100%', background: '#001529' }} justify='center'>
			<Card style={{ marginTop: '200px', width: '400px', borderRadius: '8px', maxHeight: '350px' }}>
				<Row gutter={[16, 16]} justify='center' className='logo-margin'>
					<Col span={24}>
						<Avatar size={64} icon={<UserOutlined />} />
					</Col>
					<Col span={24}>
						<Typography.Title level={4} style={{ marginLeft: '28%' }}>
							Stock Market Login
						</Typography.Title>
					</Col>
					<Col span={24}>
						<Form>
							<Form.Item name='username'>
								<Input placeholder='Username' style={{ borderRadius: '5px' }} />
							</Form.Item>
							<Form.Item name='password'>
								<Input placeholder='Password' style={{ borderRadius: '5px' }} type='password' />
							</Form.Item>
							<Form.Item name='password'>
								<Button type='primary' shape='round' style={{ width: '100%' }} onClick={handleLogin}>
									Login
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Card>
		</Row>
	);
};

export default withRouter(MutualFundsLogin);
