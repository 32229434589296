import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Avatar, Col, Layout, Menu, Modal, Rate, Result, Row } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { UserOutlined } from '@ant-design/icons';
import EmailVerifyImage from './email.svg';
import firebaseDB from './firebase';

import MainLogic from './Logics/mainLogic';
import { useValues } from 'kea';

const EmailVerify = (props: any) => {
	const { selectedProduct } = useValues(MainLogic);
	const [activeOrder, setActiveOrder] = useState([{}]);

	return (
		<Layout>
			<Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
				<Row justify='space-around'>
					<Col span={22}>
						<Menu theme='dark' mode='horizontal' style={{ width: '50%' }}>
							<Menu.Item key='1' onClick={() => props.history.push('/')}>
								Products
							</Menu.Item>
							<Menu.Item key='2'>Orders</Menu.Item>
							<Menu.Item key='3'>Basket</Menu.Item>
						</Menu>
					</Col>
					<Col span={2}>
						<Avatar icon={<UserOutlined />} />
					</Col>
				</Row>
			</Header>
			<Content className='site-layout' style={{ padding: '0 50px', marginTop: 64 }}>
				<div className='site-layout-background' style={{ padding: 24, minHeight: 380 }}>
					<Modal visible={true} footer={null} closable={false}>
						<Result
							icon={<img style={{ width: '100px', height: '100px' }} src={EmailVerifyImage}></img>}
							status='success'
							title='Restock request has been sent!'
						/>
					</Modal>
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default withRouter(EmailVerify);
