import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
	Button,
	Card,
	Col,
	Layout,
	List,
	Row,
	Space,
	Statistic,
	Rate,
	Descriptions,
	Modal,
	Result,
	Typography,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import firebaseDB from './firebase';
import { SyncOutlined } from '@ant-design/icons';

const IconText = ({ icon, text }: any) => (
	<Space>
		{React.createElement(icon)}
		{text}
	</Space>
);

const BackofficeDashboard = () => {
	const [orders, setOrders] = useState([]);
	const [activeOrders, setActiveOrders]: any = useState([]);

	useEffect(() => {
		firebaseDB.child('verifiedOrders').on('value', (snap) => {
			if (snap.val() !== null) setOrders(_.first(_.values(snap.val())));
		});
		firebaseDB.child('activeOrders/item1').on('value', (snap) => {
			if (snap.val() !== null) setActiveOrders([snap.val() as any]);
		});
	}, []);

	const emailVerification = (orderDetail: any) => {
		if (orderDetail.emailVerified)
			Modal.info({
				width: '600px',
				title: '',
				icon: null,
				content: (
					<Result
						status='success'
						title='User email is verified!'
						subTitle='Please sent the Verification Link to the user'
					/>
				),
			});
		else
			Modal.info({
				width: '600px',
				title: '',
				icon: null,
				onOk: () => window.location.reload(),
				content: (
					<Result
						status='error'
						title='User email is not verified!'
						subTitle='Please sent the Verification Link to the user'
					/>
				),
			});
	};

	const paymentlVerification = (orderDetail: any) => {
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					status='success'
					title='Payment Verified Successfully!'
					subTitle='Transaction Id: 12342244322 Order, Payment Mode: Debit Card Endign with X234'
				/>
			),
		});
	};

	const shippingAddressVerification = (item: any) => {
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					status='success'
					title='Shipping address verified successfully!'
					subTitle='Order will be delivered to given address in 5-7 working days.'
				/>
			),
		});
	};

	const orderConfirmation = (item: any) => {
		firebaseDB.child('activeOrders').remove();
		firebaseDB.child('verifiedOrders').remove();
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					status='success'
					title='Oreder confirmed!'
					subTitle='Order number: 2017182818828182881 , Shipment Number:452354544546662'
				/>
			),
		});
		firebaseDB.child('verifiedOrders').push([...orders, item], (err) => console.log(err));
	};

	const showDataCollection = () => {
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					icon={<SyncOutlined spin />}
					status='success'
					title='Collecting data from all wearhouses and suppliers...'
					subTitle='Stock report will be downloaded in a while'
				/>
			),
		});

		setTimeout(() => {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href =
				'https://www.stats.govt.nz/assets/Uploads/Annual-enterprise-survey/Annual-enterprise-survey-2019-financial-year-provisional/Download-data/annual-enterprise-survey-2019-financial-year-provisional-csv.csv';
			a.click();
		}, 5000);
	};

	return (
		<Layout>
			<Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
				<Typography.Title style={{ color: 'white', marginTop: '10px' }} level={2}>
					Backoffice
				</Typography.Title>
			</Header>
			<Content className='site-layout' style={{ padding: '0 50px', marginTop: 64 }}>
				<div className='site-layout-background' style={{ padding: 24, minHeight: 380 }}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Space direction='vertical' size='large'>
								<Card>
									<Statistic title='Pending Orders' value={_.size(activeOrders)} />
								</Card>
								<Card>
									<Row>
										<Col span={24}>
											<List
												itemLayout='vertical'
												size='small'
												dataSource={activeOrders}
												renderItem={(item: any) => (
													<List.Item
														key={item.name}
														actions={[<Rate value={item.ratings}></Rate>]}
														extra={<img width={272} alt='logo' src={item.imageUrl} />}
													>
														<List.Item.Meta
															title={<a href={item.href}>{item.name}</a>}
															description={
																<Descriptions column={1} size='small'>
																	<Descriptions.Item
																		key={item.orderId}
																		label='Oreder ID'
																	>
																		{item.ordersId}
																	</Descriptions.Item>
																	<Descriptions.Item
																		key={item.username}
																		label='Username'
																	>
																		{item.username}
																	</Descriptions.Item>
																	<Descriptions.Item
																		key={item.usermail}
																		label='Email'
																	>
																		{item.usermail}
																	</Descriptions.Item>
																</Descriptions>
															}
														/>
														<Row gutter={[16, 16]}>
															<Col span={24}>
																<Button
																	type='primary'
																	shape='round'
																	style={{ width: '100%' }}
																	onClick={() => emailVerification(item)}
																>
																	Verify Customer
																</Button>
															</Col>
															<Col span={24}>
																<Button
																	type='primary'
																	shape='round'
																	style={{ width: '100%' }}
																	onClick={() => paymentlVerification(item)}
																>
																	Verify Payment
																</Button>
															</Col>
															<Col span={24}>
																<Button
																	type='primary'
																	shape='round'
																	style={{ width: '100%' }}
																	onClick={() => shippingAddressVerification(item)}
																>
																	Verify Shipping Address
																</Button>
															</Col>
															<Col span={24}>
																<Button
																	type='primary'
																	shape='round'
																	style={{ width: '100%' }}
																	onClick={() => orderConfirmation(item)}
																>
																	Confirm Order
																</Button>
															</Col>
														</Row>
													</List.Item>
												)}
											/>
										</Col>
									</Row>
								</Card>
							</Space>
						</Col>
						<Col span={12}>
							<Space direction='vertical' size='large'>
								<Card>
									<Row>
										<Col span={12}>
											<Statistic title='Verified Orders' value={_.size(orders)} />
										</Col>
										<Col span={12}>
											<Button type='primary' size='small' onClick={showDataCollection}>
												Download stock report
											</Button>
										</Col>
									</Row>
								</Card>
								<Card>
									<Row>
										<Col span={24}>
											<List
												itemLayout='vertical'
												size='small'
												dataSource={orders}
												renderItem={(item: any) => (
													<List.Item
														key={item.name}
														actions={[<Rate value={item.ratings}></Rate>]}
														extra={<img width={272} alt='logo' src={item.imageUrl} />}
													>
														<List.Item.Meta
															title={<a href={item.href}>{item.name}</a>}
															description={
																<Descriptions column={1} size='small'>
																	<Descriptions.Item
																		key={item.orderId}
																		label='Oreder ID'
																	>
																		{item.ordersId}
																	</Descriptions.Item>
																	<Descriptions.Item
																		key={item.username}
																		label='Username'
																	>
																		{item.username}
																	</Descriptions.Item>
																	<Descriptions.Item
																		key={item.usermail}
																		label='Email'
																	>
																		{item.usermail}
																	</Descriptions.Item>
																</Descriptions>
															}
														/>
													</List.Item>
												)}
											/>
										</Col>
									</Row>
								</Card>
							</Space>
						</Col>
					</Row>
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default BackofficeDashboard;
