import { Rate } from 'antd';
import _ from 'lodash';
export const fundsColums: any = [
	{
		title: 'Name',
		dataIndex: 'Name',
		key: 'Name',
		width: 200,
	},
	{
		title: 'Morningstar Category',
		dataIndex: 'MorningstarCategory',
		key: 'MorningstarCategory',
		width: 200,
	},
	{
		title: 'NAV (AED)',
		dataIndex: 'NAV',
		key: 'NAV',
		width: 100,
	},
	{
		title: 'NAV Change (AED)',
		dataIndex: 'NAVChange',
		key: 'NAVChange',
		width: 100,
	},
	{
		title: 'NAV Change (%)',
		dataIndex: 'NAVChangePercent',
		key: 'NAVChangePercent',
		width: 100,
	},
	{
		title: '30-Day Yield (%)',
		dataIndex: '30DayYield',
		key: '30DayYield',
		width: 100,
	},
	{
		title: '7-Day Yield (%)',
		dataIndex: '7DayYield',
		key: '7DayYield',
		width: 100,
	},
	{
		title: 'Minimum Investment',
		dataIndex: 'MinimumInvestment',
		key: 'MinimumInvestment',
		width: 100,
	},
	{
		title: 'Last Dividend',
		dataIndex: 'LastDividend',
		key: 'LastDividend',
		width: 100,
	},
	{
		title: 'Morningstar- Overall',
		dataIndex: 'MorningstarOverall',
		key: 'MorningstarOverall',
		width: 100,
		align: 'center',
		render: (data: any) => <Rate value={_.first(data)} />,
	},
	{
		title: 'Morningstar- 3yrs',
		dataIndex: 'Morningstar3yrs',
		key: 'Morningstar3yrs',
		width: 100,
		align: 'center',
		render: (data: any) => <Rate value={_.first(data)} />,
	},
	{
		title: 'Morningstar- 5yrs',
		dataIndex: 'Morningstar5yrs',
		key: 'Morningstar5yrs',
		width: 100,
		align: 'center',
		render: (data: any) => <Rate value={_.first(data)} />,
	},
	{
		title: 'Morningstar- 10yrs',
		dataIndex: 'Morningstar10yrs',
		key: 'Morningstar10yrs',
		width: 100,
		align: 'center',
		render: (data: any) => <Rate value={_.first(data)} />,
	},
];
