import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Avatar, Breadcrumb, Button, Card, Col, Layout, Menu, Rate, Row, Statistic } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Meta from 'antd/lib/card/Meta';
import { UserOutlined } from '@ant-design/icons';
import firebaseDB from './firebase';

import MainLogic from './Logics/mainLogic';
import { useActions, useValues } from 'kea';

const Products = (props: any) => {
	const { setSelectedProduct } = useActions(MainLogic);

	const [selectedProduct, setProduct] = useState({});
	const [products, setProducts] = useState({});

	useEffect(() => {
		firebaseDB.child('items').on('value', (snap) => {
			if (snap.val() !== null) setProducts(_.first(_.values(snap.val())));
		});
	}, []);

	const handleBuy = (eachItem: any) => {
		setSelectedProduct(eachItem);
		props.history.push('/buy');
	};

	return (
		<Layout style={{ maxHeight: '100vh', overflow: 'scroll' }}>
			<Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
				<Row justify='space-around'>
					<Col span={22}>
						<Menu theme='dark' mode='horizontal' style={{ width: '50%' }}>
							<Menu.Item key='1' onClick={() => props.history.push('/')}>
								Products
							</Menu.Item>
							<Menu.Item key='2'>Orders</Menu.Item>
							<Menu.Item key='3'>Basket</Menu.Item>
						</Menu>
					</Col>
					<Col span={2}>
						<Avatar icon={<UserOutlined />} />
					</Col>
				</Row>
			</Header>
			<Content
				className='site-layout'
				style={{ padding: '0 50px', marginTop: 64, maxHeight: '90vh', overflow: 'scroll' }}
			>
				<div className='site-layout-background' style={{ padding: 24 }}>
					<Row gutter={[4, 24]}>
						{_.map(products, (eachItem: any) => (
							<Col span={6}>
								<Card
									size='small'
									style={{ width: 300 }}
									cover={<img alt='example' src={eachItem.imageUrl} />}
									actions={[
										<Statistic value={eachItem.cost} />,
										<Button type='primary' shape='round' onClick={() => handleBuy(eachItem)}>
											Buy
										</Button>,
									]}
								>
									<Meta title={eachItem.name} description={<Rate value={eachItem.ratings} />} />
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default withRouter(Products);
