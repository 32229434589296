import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyCy-fJtZh-u-4mekf4mwYczIu0KorYDH_U',
	authDomain: 'demodb-7448e.firebaseapp.com',
	databaseURL: 'https://demodb-7448e-default-rtdb.firebaseio.com',
	projectId: 'demodb-7448e',
	storageBucket: 'demodb-7448e.appspot.com',
	messagingSenderId: '1042690912655',
	appId: '1:1042690912655:web:b332538ca8610668d65bfa',
	measurementId: 'G-JFHZN0PHEH',
};

// Initialize Firebase
const firebasedb = firebase.initializeApp(firebaseConfig);
// firebase.analytics();
export default firebasedb.database().ref();
