import React, { useEffect } from 'react';
import _ from 'lodash';
import { Button, Card, Col, Form, Input, Layout, Menu, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import {
	AppstoreOutlined,
	BarChartOutlined,
	CloudOutlined,
	ShopOutlined,
	TeamOutlined,
	UploadOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';

const CustomLayout = (props: any) => {
	return (
		<Layout>
			<Header className='site-layout-background funds-header' style={{ padding: 0 }}>
				<Row justify='space-around'>
					<Col span={22}>
						<Typography.Title level={4} style={{ color: 'white', marginLeft: '30px', marginTop: '15px' }}>
							Mutual Fund Investments
						</Typography.Title>
					</Col>
					<Col span={2}>
						<Avatar icon={<UserOutlined />} />
					</Col>
				</Row>
			</Header>
			<Content style={{ margin: '24px 16px 0', overflow: 'initial', height: '83vh' }}>
				<div className='site-layout-background' style={{ padding: 10 }}>
					{props.children}
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default withRouter(CustomLayout);
