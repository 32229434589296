import React, { PureComponent, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import CustomLayout from './Layout';
import { Card, Tabs, Form, Col, Row, Select, DatePicker, Table, Spin, Button, Modal, Result } from 'antd';
import moment from 'moment';
import { fundsColums } from './Columns';

import firebaseDB from '../../firebase';
import { SyncOutlined } from '@ant-design/icons';
import { render } from 'react-dom';

const { TabPane } = Tabs;

const categories = ['Equity', 'Short Term', 'Long Term', 'Hybrid', 'Tax Saving'];
const customer = ['All', 'Customer 1', 'Customer 2'];

class MutualFundsDashboard extends PureComponent<any, any> {
	state = { fundData: {}, spin: false };
	componentDidMount = () => {
		firebaseDB.child('wealthData/funds/all').on('value', (snap) => {
			if (snap.val() !== null) this.setState({ fundData: snap.val() });
		});
	};

	handleSpinner = () => {
		this.setState({ spin: true });
		setTimeout(() => {
			this.setState({ spin: false });
		}, 3000);
	};

	showDataCollection = () => {
		Modal.info({
			width: '600px',
			title: '',
			icon: null,
			content: (
				<Result
					icon={<SyncOutlined spin />}
					status='success'
					title='Collecting funds data from all sorces'
					subTitle='Report will be downloaded in a while'
				/>
			),
		});

		setTimeout(() => {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href =
				'https://www.stats.govt.nz/assets/Uploads/Annual-enterprise-survey/Annual-enterprise-survey-2019-financial-year-provisional/Download-data/annual-enterprise-survey-2019-financial-year-provisional-csv.csv';
			a.click();
		}, 5000);
	};

	render() {
		return (
			<CustomLayout>
				<Spin spinning={this.state.spin}>
					<Card size='small' className='funds-card'>
						<Row gutter={[48, 0]} className='filter-label'>
							<Col span={8}>
								<Form.Item label='Customer ID'>
									<Select defaultValue={'All'} size='small'>
										{_.map(customer, (eachCategory, index) => (
											<Select.Option key={index} value={eachCategory}>
												{eachCategory}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label='Range Picker' key='2'>
									<DatePicker.RangePicker
										onChange={this.handleSpinner}
										defaultValue={[moment(), moment()]}
										size='small'
									></DatePicker.RangePicker>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Button
									shape='round'
									style={{ float: 'right' }}
									type='primary'
									onClick={this.showDataCollection}
								>
									Download Fund Report
								</Button>
							</Col>
						</Row>
					</Card>
					<br />
					<Card size='small' className='funds-card'>
						<Tabs defaultActiveKey='0' size='small'>
							{_.map(categories, (eachCategory, index) => (
								<TabPane tab={eachCategory} key={index}>
									<Table
										pagination={false}
										size='small'
										dataSource={_.get(this.state, 'fundData.equity', [])}
										columns={fundsColums}
										scroll={{ x: '100%', y: '54vh' }}
									/>
								</TabPane>
							))}
						</Tabs>
					</Card>
				</Spin>
			</CustomLayout>
		);
	}
}

export default withRouter(MutualFundsDashboard);
