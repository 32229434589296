import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Avatar, Button, Card, Col, Descriptions, Layout, Menu, Modal, Rate, Result, Row, Typography } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import Meta from 'antd/lib/card/Meta';
import { UserOutlined } from '@ant-design/icons';
import MainLogic from './Logics/mainLogic';
import { useValues } from 'kea';

const { Paragraph } = Typography;
const { info } = Modal;

// const item = {
// 	name: 'Saturn TV Unit',
// 	description: 'use it for',
// 	cost: 'AED 250',
// 	seller: 'some person',
// 	ratings: 5,
// 	imageUrl:
// 		'https://75324b7afe1a238e9728-48cce035978395103897a6b442a94265.lmsin.net/162578330-162578330-HC20102020_01-750-1.jpg',
// };

const ProductDetail = (props: any) => {
	const { selectedProduct } = useValues(MainLogic);

	const handlebuyNow = () => {
		props.history.push('/payment');
	};

	return (
		<Layout>
			<Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
				<Row justify='space-around'>
					<Col span={22}>
						<Menu theme='dark' mode='horizontal' style={{ width: '50%' }}>
							<Menu.Item key='1' onClick={() => props.history.push('/')}>
								Products
							</Menu.Item>
							<Menu.Item key='2'>Orders</Menu.Item>
							<Menu.Item key='3'>Basket</Menu.Item>
						</Menu>
					</Col>
					<Col span={2}>
						<Avatar icon={<UserOutlined />} />
					</Col>
				</Row>
			</Header>
			<Content
				className='site-layout'
				style={{ padding: '0 50px', marginTop: 64, maxHeight: '90vh', overflow: 'scroll' }}
			>
				<div className='site-layout-background' style={{ padding: 24, minHeight: 380 }}>
					<Row gutter={[24, 24]}>
						<Col span={12}>
							<img style={{ maxWidth: '100%', maxHeight: '100%' }} src={selectedProduct.imageUrl}></img>
						</Col>
						<Col span={12}>
							<Card
								style={{ width: '100%', height: '100%' }}
								size='small'
								title={
									<Row>
										<Col span={24}>
											<Typography.Title level={3}>{selectedProduct.name}</Typography.Title>
										</Col>
										<Col span={24}>
											<Typography.Text>{`by: ${selectedProduct.seller}`}</Typography.Text>
										</Col>
										<Col span={12}>
											<Rate value={selectedProduct.ratings}></Rate>
										</Col>
										<Col span={12}>
											<Typography.Title level={4}>{selectedProduct.cost}</Typography.Title>
										</Col>
									</Row>
								}
							>
								<Row gutter={[16, 16]} justify='center'>
									<Col span={24}>
										<Descriptions size='small' title='About this item' bordered column={1}>
											<Descriptions.Item label='Seat frame'>100% polyester</Descriptions.Item>
											<Descriptions.Item label='Back frame'>
												Particleboard, Solid pine, Polyurethane foam 30 kg/cu.m., Polyester
												wadding, Non-woven polypropylene, Fibreboard
											</Descriptions.Item>
											<Descriptions.Item label='Back cushion'>
												Particleboard, Fibreboard, Solid pine, Polyester wadding, Non-woven
												polypropylene, Polyurethane foam 20 kg/cu.m., Paperboard (min. 0%
												recycled)
											</Descriptions.Item>
											<Descriptions.Item label='Hinge'>
												70% cut polyurethane foam/ 30% polyester fibres
											</Descriptions.Item>
											<Descriptions.Item label='Width' span={2}>
												69 cm
											</Descriptions.Item>
											<Descriptions.Item label='Height'>69 cm</Descriptions.Item>
											<Descriptions.Item label='Length'>134 cm</Descriptions.Item>
											<Descriptions.Item label='Weight'>23 kg</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col span={24}>
										<Button
											style={{ float: 'right' }}
											type='primary'
											shape='round'
											onClick={handlebuyNow}
										>
											Buy Now
										</Button>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</Content>
			<Footer style={{ textAlign: 'center' }}>RPA Demo ©2021 Created by Grape Technology</Footer>
		</Layout>
	);
};

export default withRouter(ProductDetail);
